import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-instructions" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "form-header"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    (!$setup.showUnlockedView)
      ? (_openBlock(), _createBlock($setup["ContactForm"], {
          key: 0,
          "form-settings-key": $props.formSettingsKey,
          "widget-data": $props.widgetData,
          "custom-data": $props.customData
        }, _createSlots({ _: 2 /* DYNAMIC */ }, [
          (!$setup.isNil($setup.customFormHeading) && $setup.customFormHeading != '')
            ? {
                name: "heading",
                fn: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    ($setup.ryh)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
                          _createElementVNode("strong", null, _toDisplayString($setup.customFormHeading), 1 /* TEXT */)
                        ]))
                      : (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString($setup.customFormHeading), 1 /* TEXT */))
                  ])
                ]),
                key: "0"
              }
            : undefined
        ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["form-settings-key", "widget-data", "custom-data"]))
      : (_openBlock(), _createBlock($setup["ContactPostSubmitStatement"], {
          key: 1,
          ryh: $setup.ryh
        }, null, 8 /* PROPS */, ["ryh"]))
  ]))
}