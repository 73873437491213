import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "form-completion-message after-complete" }
const _hoisted_2 = {
  key: 0,
  class: "email",
  src: "https://static.mastercontrol.com/assets/persist/images/mcui-resources-sent.png",
  alt: "email sent"
}
const _hoisted_3 = {
  key: 1,
  class: "secondary-heading color-teal text-center"
}
const _hoisted_4 = {
  key: 2,
  class: "secondary-heading color-teal text-center"
}
const _hoisted_5 = { class: "text-center" }
const _hoisted_6 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!$props.ryh)
      ? (_openBlock(), _createElementBlock("img", _hoisted_2))
      : _createCommentVNode("v-if", true),
    (!$props.ryh)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString($setup.t($setup.ContactFormPostKeysEnum.contactThankYou, {ns: $setup.TranslationNamespaceEnum.contactPostSubmit})), 1 /* TEXT */))
      : (_openBlock(), _createElementBlock("h2", _hoisted_4, _toDisplayString($setup.t($setup.ContactFormPostKeysEnum.raiseYourHand, {ns: $setup.TranslationNamespaceEnum.contactPostSubmit})), 1 /* TEXT */)),
    _createElementVNode("p", _hoisted_5, [
      _createVNode($setup["translation"], {
        translation: $setup.t($setup.ContactFormPostKeysEnum.contactMessage, { ns: $setup.TranslationNamespaceEnum.contactPostSubmit })
      }, {
        "phone-number-slot": _withCtx(() => [
          _createElementVNode("a", {
            href: $setup.t($setup.ContactFormPostKeysEnum.contactHrefValue, { ns: $setup.TranslationNamespaceEnum.contactPostSubmit }),
            "data-st-wn": ""
          }, _toDisplayString($setup.t($setup.ContactFormPostKeysEnum.contactPhoneNumber, { ns: $setup.TranslationNamespaceEnum.contactPostSubmit })), 9 /* TEXT, PROPS */, _hoisted_6)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["translation"])
    ])
  ]))
}