<script setup lang="ts">
  import { TranslationComponent as translation, useTranslation } from "i18next-vue";
  import { ContactFormPostKeysEnum } from "../locale/translation-keys/all-keys.js";
  import { TranslationNamespaceEnum } from "../locale/settings/translation-namespaces.js";
  defineProps<{
    ryh?: boolean
  }>();
  const { t } = useTranslation();
</script>

<template>
  <div class="form-completion-message after-complete">
    <img v-if="!ryh" class="email" src="https://static.mastercontrol.com/assets/persist/images/mcui-resources-sent.png" alt="email sent">
    
    <h2 class="secondary-heading color-teal text-center" v-if="!ryh">{{ t(ContactFormPostKeysEnum.contactThankYou, {ns: TranslationNamespaceEnum.contactPostSubmit}) }}</h2>
    <h2 class="secondary-heading color-teal text-center" v-else>{{ t(ContactFormPostKeysEnum.raiseYourHand, {ns: TranslationNamespaceEnum.contactPostSubmit}) }}</h2>

    <p class="text-center">
      <translation
            :translation="t(ContactFormPostKeysEnum.contactMessage, { ns: TranslationNamespaceEnum.contactPostSubmit })"
      >
        <template #phone-number-slot>
          <a :href="t(ContactFormPostKeysEnum.contactHrefValue, { ns: TranslationNamespaceEnum.contactPostSubmit })" data-st-wn="">
            {{ t(ContactFormPostKeysEnum.contactPhoneNumber, { ns: TranslationNamespaceEnum.contactPostSubmit }) }}
          </a>
        </template>
      </translation>
    </p>
  </div>
</template>